.input {

    $this: &;

    &__wrap {
        min-height: 50px;
    }

    input,
    textarea,
    select {
        transition: border-color 0.3s;
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            -webkit-text-fill-color: currentColor;
            -webkit-box-shadow: 0 0 0px 1000px $colorWhite inset;
        }
    }

    label {
        transition: transform 0.15s;
        transform-origin: left top;
    }

    &:not(.error) {
        input,textarea,select {
            border-color: currentColor;
        }
    }

    &[data-component-booted] textarea {
        resize: none;
    }

    input:not(:placeholder-shown) + label,
    textarea:not(:placeholder-shown) + label,
    input:focus + label,
    textarea:focus + label,
    &.js-has-value label {
        transform: translate(0, -22px) scale(0.75, 0.75);
    }
}
