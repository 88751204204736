.wysiwyg {

    //line-height: normal;

    > * {
        margin-bottom: (32/20) * 1em;
    }

    *:last-child {
        margin-bottom: 0;
    }

    *:first-child {
        margin-top: 0;
    }

    li {
        padding-left: 1.75em;
        position: relative;
        margin-bottom: (32/20) * 1em;

        &::before {
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    ul li::before {
        content: "→";
    }

    ol {
        counter-reset: list;
    }

    ol li {
        counter-increment: list;
    }

    ol li::before {
        content: counter(list) ".";
    }

    ol ol li::before {
        content: counter(list, lower-alpha) ".";
    }

    li ul,
    li ol {
        padding-top: (32/20) * 1em;
    }

    strong, b {
        font-weight: bold;
    }

    i, em {
        font-style: italic;
    }

    h2, h3, h4 {
        margin-top: 56px;
        margin-bottom: 32px;
        line-height: 1;

        strong, b, em, i {
            font-weight: normal;
            font-style: normal;
        }
    }

    a:not([class*="link"]) {

        text-decoration: underline;

        &:active {
            text-decoration-color: transparent;
        }

        @media (hover: hover) {
            transition: text-decoration-color 0.3s $easeOutCubic;
            &:hover {
                text-decoration-color: transparent;
            }
        }

    }

    .link {
        padding-bottom: 0;
    }

}
