/*

    @font-face declarations

    Paths to url() should be relative to the SASS entrypoint (base.scss), i.e. "../fonts/fontname.woff2"

 */

@layer base {

    // Regular
    @font-face {
        font-family: "Alphabeta";
        font-weight: normal;
        font-style: normal;
        font-display: fallback;
        src: url('../fonts/DesignitAlphabeta-Regular.woff2') format('woff2'), url('../fonts/DesignitAlphabeta-Regular.woff') format('woff');
    }

    // Regular Italic
    @font-face {
        font-family: "Alphabeta";
        font-weight: normal;
        font-style: italic;
        font-display: fallback;
        src: url('../fonts/DesignitAlphabeta-RegularItalic.woff2') format('woff2'), url('../fonts/DesignitAlphabeta-RegularItalic.woff') format('woff');
    }

    // Bold
    @font-face {
        font-family: "Alphabeta";
        font-weight: 700;
        font-style: normal;
        font-display: fallback;
        src: url('../fonts/DesignitAlphabeta-Bold.woff2') format('woff2'), url('../fonts/DesignitAlphabeta-Bold.woff') format('woff');
    }

    // Bold Italic
    @font-face {
        font-family: "Alphabeta";
        font-weight: 700;
        font-style: italic;
        font-display: fallback;
        src: url('../fonts/DesignitAlphabeta-BoldItalic.woff2') format('woff2'), url('../fonts/DesignitAlphabeta-BoldItalic.woff') format('woff');
    }

    // Light
    @font-face {
        font-family: "Alphabeta";
        font-weight: 300;
        font-style: normal;
        font-display: fallback;
        src: url('../fonts/DesignitAlphabeta-Light.woff2') format('woff2'), url('../fonts/DesignitAlphabeta-Light.woff') format('woff');
    }

    // Light Italic
    @font-face {
        font-family: "Alphabeta";
        font-weight: 300;
        font-style: italic;
        font-display: fallback;
        src: url('../fonts/DesignitAlphabeta-LightItalic.woff2') format('woff2'), url('../fonts/DesignitAlphabeta-LightItalic.woff') format('woff');
    }
}
