@import "./base/includes";
@import "./base/variables";

/* ============================
    HTML reset and font-face rules
 ============================ */

/*! purgecss start ignore */
@import "./base/fonts";
@import "tailwindcss/base";
@import "./base/reset"; // Our own reset styles
/*! purgecss end ignore */

/* ============================
    Grid
 ============================ */
@import "./base/grid";

/*! purgecss start ignore */
// This needs to be here, in order to have the Viewport.on('breakpoint', handler) work properly :)
@include breakpointer();
/*! purgecss end ignore */
