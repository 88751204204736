.features {
    &.is-dragging {
        a, button {
            pointer-events: none !important;
            cursor: -webkit-grabbing !important;
            cursor: grabbing !important;
        }
    }
    &.js-hide-cursor {
        a, button, [class^="flickity"] {
            cursor: none !important;
        }
        a, button {
            pointer-events: none !important;
        }
    }
}
