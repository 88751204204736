/**
========================
Custom Tailwind utilities
https://tailwindcss.com/docs/adding-new-utilities
========================

All Tailwind-powered utilities need to render inside the `@layer utilities` directive.
Tailwind will automatically create responsive classes for any utilities rendered inside the `@responsive` directive.
If you need Tailwind to create pseudo-class variant classes (e.g. for :hover or :focus), use the `@variants` directive.
The `@variants` and `@responsive` directives can be combined.

https://tailwindcss.com/docs/adding-new-utilities#generating-responsive-variants
https://tailwindcss.com/docs/adding-new-utilities#generating-pseudo-class-variants

 */

@layer utilities {

    @responsive {

        .gpu {
            @include gpu();
        }

        .anti {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        .round {
            border-radius: 100%;
        }

        .full {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }

        .fullheight {
            height: 100vh;
            @supports (--custom:property) {
                height: calc(var(--vh, 1vh) * 100);
            }
        }

        .min-fullheight {
            min-height: 100vh;
            @supports (--custom:property) {
                min-height: calc(var(--vh, 1vh) * 100);
            }
        }

        .clear {
            &:before, &:after {
                content: "";
                display: table;
            }

            &:after {
                clear: both;
            }
        }

        .center {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .center-x {
            left: 50%;
            transform: translateX(-50%);
        }

        .center-y {
            top: 50%;
            transform: translateY(-50%);
        }

        /*
        .object-cover {
            object-fit: cover;
            width: 100%;
            height: 100%;

            html.no-objectfit & {
                object-fit: none !important;
                width: auto;
                height: auto;
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: none;
                max-height: none;
                min-width: 100%;
                min-height: 100%;
            }
        }

        .object-contain {
            object-fit: contain;
            width: 100%;
            height: 100%;

            html.no-objectfit & {
                object-fit: none !important;
                width: auto;
                height: auto;
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 100%;
                max-height: 100%;
            }
        }*/

        /*
            Object-fit (override Tailwind's default
         */
        .object-cover {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        html.no-objectfit .object-cover,
        html.edge video.object-cover {
            object-fit: none !important;
            width: auto;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: none;
            max-height: none;
            min-width: 100%;
            min-height: 100%;
            object-position: 50% 50% !important;
        }

        .object-contain {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }

        html.no-objectfit .object-contain,
        html.edge video.object-contain {
            object-fit: none !important;
            width: auto;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 100%;
            object-position: 50% 50% !important;
        }

        .placed-wrap {
            position: relative;
            height: 0;
        }

        .placed-image {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }

        .js {
            html:not(.js) & {
                display: none;
            }
        }

        .no-js {
            html:not(.no-js) & {
                display: none;
            }
        }

        .scrollable {
            @include scrollable();
        }

        .scrollable-x {
            @include scrollableX();
        }

        .ellipsis {
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
            max-width: 100%;
            white-space: nowrap;

            &.link {
                line-height: normal;
            }
        }

        // CSS columns
        .columns-2 {
            column-count: 2;
            column-gap: gutter();
            @each $breakpoint in map-keys($breakpoints) {
                @screen #{$breakpoint} {
                    column-gap: gutter($breakpoint);
                }
            }
        }

        .columns-3 {
            column-count: 3;
            column-gap: gutter();
            @each $breakpoint in map-keys($breakpoints) {
                @screen #{$breakpoint} {
                    column-gap: gutter($breakpoint);
                }
            }
        }

        // Links
        .link {

            text-decoration: none !important;

            background-image:
                    linear-gradient(currentColor, currentColor),
                    linear-gradient(#CCCCCC, #CCCCCC);

            .text-white & {
                background-image:
                        linear-gradient(currentColor, currentColor),
                        linear-gradient(#333333, #333333);
            }

            @supports (--custom:property) {
                background-image:
                        linear-gradient(var(--color, currentColor), var(--color, currentColor)),
                        linear-gradient(#CCCCCC, #CCCCCC);

                .text-white & {
                    background-image:
                            linear-gradient(var(--color, currentColor), var(--color, currentColor)),
                            linear-gradient(#333333, #333333);
                }
            }

            background-position: 0% 100%, 0% 100%;
            background-repeat: no-repeat;
            background-size: 0% 1px, 100% 1px;
            padding-bottom: 0.1em;

            .using-touch &:active,
            .using-touch a:active & {
                background-size: 100% 1px, 100% 1px;
            }

            @media (hover: hover) {
                .no-js & {
                    transition: background-size 0.3s $easeOutQuad;
                    &:hover,
                    a:hover & {
                        background-size: 100% 1px, 100% 1px;
                    }
                }
            }
        }

        .link-alt {

            text-decoration: none !important;

            background-image: linear-gradient(currentColor, currentColor);
            background-position: 0% 100%;
            background-repeat: no-repeat;
            background-size: 0% 0.075em;
            padding-bottom: 0.1em;

            &.is-current,
            .using-touch &:active {
                background-size: 100% 0.07em !important;
            }

            @media (hover: hover) {
                .no-js & {
                    transition: background-size 0.3s $easeOutQuad;
                    &:hover,
                    a:hover &,
                    &.is-current {
                        background-size: 100% 0.07em;
                    }
                }
            }

        }

        .external:after {
            content: " ↗";
        }

        .internal:after {
            content: " →";
        }

        .objectfit .zoom {
            @media (hover: hover) {
                [data-zoomtarget] {
                    transition: transform 0.6s $easeOutQuad;
                    will-change: transform;
                }
                &:hover [data-zoomtarget] {
                    transform: scale(1.025);
                    transition: transform 0.4s $easeOutQuad;
                }
            }
        }

    }

}
