@mixin grid-item($breakpoint: null) {
    $breakpoint-key: $breakpoint;

    .g-col {
        @if $breakpoint == null {
            $breakpoint-key: 'min';
            width: 100%;
            @if $grid-flexbox {
                flex: 0 0 auto;

                &--no-flex {
                    float: left;
                }
            } @else {
                float: left;
            }

            &--has-sub {
                padding-left: 0 !important;
                padding-right: 0 !important;

                @if $grid-flexbox {
                    display: flex;
                    flex-wrap: wrap;

                    &-no-flex {
                        *zoom: 1;
                        display: block;

                        &:before, &:after {
                          content: ""; display: table;
                        }

                        &:after {
                          clear: both;
                        }
                    }
                } @else {
                    *zoom: 1;
                    display: block;

                    &:before, &:after {
                      content: ""; display: table;
                    }

                    &:after {
                      clear: both;
                    }
                }
            }

            &--no-pad {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }
        $padding: grid-get-value-from-map($breakpoint-key, $grid-gutters)/2;
        padding-left: $padding;
        padding-right: $padding;
    }
}
