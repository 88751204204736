@mixin grid-get-breakpoint-min-width($breakpoint) {
	$min-width: 0;

	@if $breakpoint {
		@if type-of($breakpoint) == number {
			$min-width: $breakpoint;
		} @else {
			$min-width: grid-get-value-from-map($breakpoint, $grid-breakpointMap);
		}
	}

	@if $min-width != 0 {
		@media (min-width: grid-convert-breakpoint-unit($min-width)) {
			@content;
		}
	}
}
