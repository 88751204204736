.story-feature {

    img {
        object-fit: cover;
        @include until(sp) {
            min-height: 150vw;
        }
        @include from(mp) {
            max-height: calc(100vh - 176px); // 120px (top margin to account for global header/logo) + 56px (bottom margin)
            @supports (--custom:property) {
                max-height: calc((var(--vh, 1vh) * 100) - 176px);
            }
        }
    }

    &__overlay {
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5) 100%);
    }

    // The "bar" is just there to basically make the featured story and the adjacent top stories appear to align when the feature is sticky
    &__bar {
        width: calc(100vw - #{outerMargin(l) * 2});
        max-width: $maxContentWidth;
        top: -30px;
    }

    @media (hover: hover) {
        a:hover &__btn {
            background-color: $colorBlack;
            color: $colorWhite;
        }
    }
}
