.page-footer {

    &__up {
        width: 56px;
        height: 56px;

        @include from(l) {
            width: 80px;
            height: 80px;
        }

        &-text {
            transition: transform 0.75s $easeOutQuint;
            span {
                transition: transform 0.3s;
            }
        }

        @media (hover: hover) {
            &:hover &-text {
                transform: translate(0, -100%);
            }
        }
    }

    &__some {
        width: 56px;
        height: 56px;

        @include from(l) {
            width: 40px;
            height: 40px;
        }

        &-text {
            transform: translate(-100%, 0);
        }

        &-text {
            transition: transform 0.75s $easeOutQuint;
            span {
                transition: transform 0.3s;
            }
        }

        @media (hover: hover) {
            &:hover &-text {
                transform: translate(0, 0);
            }
        }
    }
}
