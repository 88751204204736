.filters {

    &__dot {

        width: 8px;
        height: 8px;

        [aria-pressed="true"] &,
        [data-filter]:not([aria-disabled="true"]):not([aria-pressed="true"]):hover & {
            background-color: currentColor;
        }

        &-x {
            width: 9px;
            height: 9px;
        }

        @media (hover: hover) {
            [aria-pressed="true"]:hover & {
                background-color: transparent;
                border-color: transparent;
                transition: background-color 0s 0.1s, border-color 0s 0.1s;
            }

            [aria-pressed="true"]:hover &-x {
                opacity: 1;
                transition: opacity 0s 0.1s;
            }
        }

    }

    &__close,
    &__search svg {
        width: 18px;
        height: 18px;
        @include from(l) {
            width: 24px;
            height: 24px;
        }
    }

}
