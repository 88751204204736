/**
========================
Custom Tailwind components
https://tailwindcss.com/docs/extracting-components
========================
 */

@layer components {

    /**
        Typography components
     */
    .h1 {
        @apply text-56 s:text-72 m:text-80 l:text-140 xl:text-200 leading-1;
    }

    .h1-small {
        @apply text-56 s:text-72 m:text-80 l:text-120 xl:text-140 leading-1;
    }

    .h2 {
        @apply text-32 l:text-40 xl:text-56;
    }

    .h3 {
        @apply text-28 l:text-32 xl:text-36 leading-1_25;
    }

    .body {
        @apply text-18 l:text-20 leading-1_3;
    }

    .big-text {
        @apply text-32 l:text-56 xl:text-72;
    }

    .inactive {
        @apply text-gray;
    }

    .cta {
        @apply font-bold text-22 l:text-28 transition-colors duration-300;
        &[aria-disabled="true"],
        &.disabled {
            @apply inactive;
            cursor: default;
        }
    }

    /**
        Layout components
     */

    // Add top padding to account for the fixed global header
    .pad-top {
        @apply pt-165 l:pt-260;
    }

    // Matrix content blocks (vertical whitespace)
    .content-block {
        @apply mb-80 m:mb-100 l:mb-140;
        .hero-image + &.text-block,
        &.text-block + &.text-block,
        &.text-block + &.attributions {
            @apply -mt-40 m:-mt-60 l:-mt-80;
        }
        &.text-block + &.video-embed,
        &.video-embed + &.text-block,
        &.text-block + &.quote-block,
        &.quote-block + &.text-block {
            @apply -mt-20 m:-mt-40 l:-mt-60;
        }
        &.image-grid + &.image-grid {
            @apply -mt-80 m:-mt-100 l:-mt-140;
        }
        .bio-page &,
        .brand-page & {
            @apply mb-80 m:mb-100 l:mb-160;
        }
    }

    .skiplink {
        &:not(:focus) {
            @apply sr-only;
        }
    }

}
