@mixin grid-widths($columns, $push, $push-cols, $pull, $pull-cols, $breakpoint: null) {
    $breakpoint-suffix: '';
    @if $breakpoint != null {
        $breakpoint-suffix: \@#{$breakpoint};
    }

    .g-left#{$breakpoint-suffix} {
        margin-left: 0;

        &.g-col--no-flex {
            float: left;
        }
        @if not $grid-flexbox {
            float: left;
        }
    }
    @if $push {
        .g-push-0#{$breakpoint-suffix} {
            margin-left: 0;
        }
    }
    @if $pull {
        .g-pull-0#{$breakpoint-suffix} {
            margin-right: 0;
        }
    }
    @each $column in $columns {
        @if $column == 1 {
            // no point outputting 100% several times so lets just do it once
            $colname: '';
            @if $grid-use-long-classnames {
                $colname: \/#{$column};
            }

            .g-#{$column}#{$colname}#{$breakpoint-suffix} {
                width: 100%;
            }
        } @else {
            @for $i from 1 through $column {
                $colname: '';
                @if $grid-use-long-classnames {
                    $colname: \/#{$column};
                }

                .g-#{$i}#{$colname}#{$breakpoint-suffix} {
                    @if $grid-flexbox {
                        max-width: grid-columns($i / $column, $columns);
                        flex-basis: grid-columns($i / $column, $columns);

                        &.g-col--no-flex {
                            width: grid-columns($i / $column, $columns);
                            float: left;
                        }
                    } @else {
                        width: grid-columns($i / $column, $columns);
                        float: left;
                    }
                }
                @if $push and $i <= $push-cols {
                    .g-push-#{$i}#{$breakpoint-suffix} {
                        margin-left: grid-columns($i / $column, $columns);
                    }
                }
                @if $pull and $i <= $pull-cols {
                    .g-pull-#{$i}#{$breakpoint-suffix} {
                        margin-right: grid-columns($i / $column, $columns);
                    }
                }
            }
        }
    }

    .g-right#{$breakpoint-suffix} {
        @if $grid-flexbox {
            margin-left: auto;
        } @else {
            float: right;
        }

        &.g-col--no-flex {
            float: right;
        }
    }
}
