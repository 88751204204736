.page-header {

    $header: &;

    &__bar {
        height: 90px;
    }

    &__logo {
        width: 70px;
        height: 24px;

        @include from(m) {
            width: 88px;
            height: 30px;
            margin-top: 4px;
        }

        @include from(l) {
            width: 106px;
            height: 36px;
            margin-top: -2px;
        }
    }

    &__menu {

        $this: &;

        @include until(l) {

            &-burger {

                width: 24px;
                height: 8px;

                top: -5px;

                @include from(m) {
                    top: -3px;
                }

                span {
                    height: 2px;
                    transition: transform 0.3s;
                    &:first-child {
                        top: 0;
                    }
                    &:last-child {
                        bottom: 0;
                    }
                }

                #{$header}:not([data-component-booted]) #{$this}:target &,
                & a[aria-expanded="true"] {
                    span:first-child {
                        transform: rotate(135deg) translate(2px, -1px);
                    }
                    span:last-child {
                        transform: rotate(45deg) translate(-3px, -2px);
                    }
                }

            }

            &-close {
                #{$header}:not([data-component-booted]) #{$this}:target & {
                    display: block;
                }
            }

            &-items {

                #{$header}:not([data-component-booted]) #{$this}:target & {
                    display: flex;
                }

                @include scrollable;
                scrollbar-width: none;
                -ms-overflow-style: -ms-autohiding-scrollbar;
                &::-webkit-scrollbar {
                    display: none;
                }

                padding-left: outerMargin();
                padding-right: outerMargin();

                @each $breakpoint in (s, sp, m, mp) {
                    @include from($breakpoint) {
                        padding-left: outerMargin($breakpoint);
                        padding-right: outerMargin($breakpoint);
                    }
                }

            }

            &-item {

                padding-bottom: 0;

                @include from(s) {
                    font-size: #{'max(56px, 12vw)'};
                    @include breakpoint((orientation landscape)) {
                        font-size: #{'max(56px, 10vh)'};
                    }
                }
            }

        }

    }
}
