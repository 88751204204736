@mixin screenreader {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

@mixin verticallyAligned($pos:relative) {
    position: $pos;
    top: 50%;
    transform: translateY(-50%);
}

@mixin aspect($width, $height, $context: 100%) {
    position: relative;
    display: block;
    content: "";
    width: $context;
    height: 0;
    padding-top: ($height / $width) * $context;
}

@mixin gpu() {
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
}

@mixin letter-spacing($space, $fontSize) {
    letter-spacing: ($space / $fontSize) + 0em;
}

@mixin line-height($line, $fontSize) {
    line-height: ($line / $fontSize);
}

@mixin placeholderColor($color) {
    &::-webkit-input-placeholder {
        color: $color;
    }
    &::-moz-placeholder { /* Firefox 19+ */
        color: $color;
    }
    &:-ms-input-placeholder { /* IE 10+ */
        color: $color;
    }
    &:-moz-placeholder { /* Firefox 18- */
        color: $color;
    }
}

@mixin scrollable {
    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
}

@mixin scrollableX {
    overflow: hidden;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
}
