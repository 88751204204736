.checkbox {

    input {
        width: 26px;
        height: 26px;
        margin: 1px 0 0 1px;
    }

    input:checked + &__box svg {
        opacity: 1 !important;
    }

    &__box {
        width: 28px;
        height: 28px;

        svg {
            transition: opacity 0.15s linear;
        }

        @media (hover: hover) {
            &:hover svg {
                opacity: 0.15;
            }
        }
    }

}
