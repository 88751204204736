/* ==============================
    Basic/generic global styles
 ============================== */

body {
    min-height: calc(100% + 1px);
    font-variant-ligatures: contextual;
    font-feature-settings: "calt";
    overflow-x: hidden;
}

.lazyload,
.lazyloading {
    opacity: 0;
}

.lazyloaded {
    opacity: 1;
    transition: opacity 0.3s $easeOutQuad;
}

.objectfit .lazyload.lazyzoom,
.objectfit .lazyloading.lazyzoom {
    transform: scale(1.03, 1.03) translate(0, 1vh);
    transform-origin: center center;
}

.objectfit .lazyloaded.lazyzoom {
    transform: scale(1, 1) translate(0, 0);
    transform-origin: center bottom;
    transition: opacity 0.5s $easeInQuad, transform 1s $easeOutQuad;
}

.no-js {
    img.lazyload {
        display: none;
    }
}

[hidden]:not(:target) {
    display: none !important;
}

.g-wrap {
    // Battling a weird flexbox bug where... this helps :|
    width: 100%;
}

th, td {
    vertical-align: top;
    text-align: left;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

input:required {
    box-shadow: none;
}

html.no-outline * {
    outline: none;
}

::-moz-selection {
    background: $colorText;
    color: $colorBackground;
}

::selection {
    background: rgba($colorText, 0.99);
    color: $colorBackground;
}

html.js:not(.js-error) [data-fittext]:not(.js-fitted),
html.js:not(.js-error):not(.is-preview.is-iframe) [data-component="ScrollText"]:not(.js-init),
html.js:not(.js-error):not(.is-preview.is-iframe) [data-reveal]:not([data-revealed]) {
    opacity: 0;
}

button, a {
    touch-action: manipulation;
}

.ss02 {
    font-feature-settings: "ss02";
}

video[playsinline] {
    pointer-events: none;
}
