.accordions {

    &__icon {
        width: 18px;
        height: 18px;
    }

    &__panel {
        top: -10px;

        html.no-js &[hidden]:target {
            display: block !important;
        }
    }
}
