@mixin columnSpan($columnSpan) {
    -ms-grid-column-span: $columnSpan;
    grid-column: span $columnSpan / span $columnSpan;
}

@mixin rowSpan($rowSpan) {
    -ms-grid-row-span: $rowSpan;
    grid-row: span $rowSpan / span $rowSpan;
}

@mixin columnStart($columnStart) {
    -ms-grid-column: $columnStart;
}

@mixin rowStart($rowStart) {
    -ms-grid-row: $rowStart;
}

@mixin showPortrait {
    .landscape {
        display: none;
    }
    .portrait {
        display: block;
    }
}

.cases-grid {

    &:last-child {
        margin-bottom: -32px;
    }

    @include from(m) {

        display: -ms-grid;
        display: grid;
        grid-template-columns: repeat(12, minmax(0, 1fr));
        -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

        // We can't use grid-gap due to IE11, so faking it with gutters and width: calc()
        width: calc(100% + 32px);
        margin-left: -16px;

    }

    &__item {
        margin-bottom: 32px;

        .objectfit & video {
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            max-width: calc(100% + 2px);
            margin-left: -1px;
            margin-top: -1px;
        }

        @include from(m) {
            // Again with the fake gap
            margin-left: 16px;
            margin-right: 16px;
        }
    }

    // 1-2 item grid layout
    &--2 &__item {
        &:nth-child(1) {
            @include columnStart(1);
            @include columnSpan(6);
            @include rowStart(1);
            @include rowSpan(1);
        }
        &:nth-child(2) {
            @include columnStart(7);
            @include columnSpan(6);
            @include rowStart(1);
            @include rowSpan(1);
        }
    }

    // 3 item grid layout
    &--3 &__item {

        &:nth-child(1) {
            @include columnStart(1);
            @include columnSpan(6);
            @include rowStart(1);
            @include rowSpan(1);
        }

        &:nth-child(2) {
            @include columnStart(7);
            @include columnSpan(3);
            @include rowStart(1);
            @include rowSpan(1);
            @include showPortrait;
        }

        &:nth-child(3) {
            @include columnStart(10);
            @include columnSpan(3);
            @include rowStart(1);
            @include rowSpan(1);
            @include showPortrait;
        }

    }

    // 4 item grid layout
    &--4 &__item {

        &:nth-child(1) {
            @include columnStart(1);
            @include columnSpan(6);
            @include rowStart(1);
            @include rowSpan(2);
            @include showPortrait;
        }

        &:nth-child(2) {
            @include columnStart(7);
            @include columnSpan(6);
            @include rowStart(1);
            @include rowSpan(1);
        }

        &:nth-child(3) {
            @include columnStart(7);
            @include columnSpan(3);
            @include rowStart(2);
            @include rowSpan(1);
            @include showPortrait;
        }

        &:nth-child(4) {
            @include columnStart(10);
            @include columnSpan(3);
            @include rowStart(2);
            @include rowSpan(1);
            @include showPortrait;
        }

    }

    // 5 item grid layout
    &--5 &__item {

        &:nth-child(1) {
            @include columnStart(1);
            @include columnSpan(6);
            @include rowStart(1);
            @include rowSpan(1);
        }

        &:nth-child(2) {
            @include columnStart(7);
            @include columnSpan(6);
            @include rowStart(1);
            @include rowSpan(1);
        }

        &:nth-child(3) {
            @include columnStart(1);
            @include columnSpan(3);
            @include rowStart(2);
            @include rowSpan(1);
            @include showPortrait;
        }

        &:nth-child(4) {
            @include columnStart(4);
            @include columnSpan(6);
            @include rowStart(2);
            @include rowSpan(1);
        }

        &:nth-child(5) {
            @include columnStart(10);
            @include columnSpan(3);
            @include rowStart(2);
            @include rowSpan(1);
            @include showPortrait;
        }

    }

    // 6 item grid layout
    &--6 &__item {

        &:nth-child(1) {
            @include columnStart(1);
            @include columnSpan(6);
            @include rowStart(1);
            @include rowSpan(1);
        }

        &:nth-child(2) {
            @include columnStart(7);
            @include columnSpan(6);
            @include rowStart(1);
            @include rowSpan(2);
            @include showPortrait;
        }

        &:nth-child(3) {
            @include columnStart(1);
            @include columnSpan(6);
            @include rowStart(2);
            @include rowSpan(1);
        }

        &:nth-child(4) {
            @include columnStart(1);
            @include columnSpan(3);
            @include rowStart(3);
            @include rowSpan(1);
            @include showPortrait;
        }

        &:nth-child(5) {
            @include columnStart(4);
            @include columnSpan(3);
            @include rowStart(3);
            @include rowSpan(1);
            @include showPortrait;
        }

        &:nth-child(6) {
            @include columnStart(7);
            @include columnSpan(6);
            @include rowStart(3);
            @include rowSpan(1);
        }

    }

    // 6+ item grid layout (default)
    &--default &__item {

        &:nth-child(1) {
            @include columnStart(1);
            @include columnSpan(6);
            @include rowStart(1);
            @include rowSpan(1);
        }

        &:nth-child(2) {
            @include columnStart(7);
            @include columnSpan(6);
            @include rowStart(1);
            @include rowSpan(2);
            @include showPortrait;
        }

        &:nth-child(3) {
            @include columnStart(1);
            @include columnSpan(6);
            @include rowStart(2);
            @include rowSpan(1);
        }

        &:nth-child(4) {
            @include columnStart(1);
            @include columnSpan(3);
            @include rowStart(3);
            @include rowSpan(1);
            @include showPortrait;
        }

        &:nth-child(5) {
            @include columnStart(4);
            @include columnSpan(6);
            @include rowStart(3);
            @include rowSpan(1);
        }

        &:nth-child(6) {
            @include columnStart(10);
            @include columnSpan(3);
            @include rowStart(3);
            @include rowSpan(1);
            @include showPortrait;
        }

        &:nth-child(7) {
            @include columnStart(1);
            @include columnSpan(6);
            @include rowStart(4);
            @include rowSpan(1);
        }

        &:nth-child(8) {
            @include columnStart(7);
            @include columnSpan(6);
            @include rowStart(4);
            @include rowSpan(1);
        }

        &:nth-child(9) {
            @include columnStart(1);
            @include columnSpan(6);
            @include rowStart(5);
            @include rowSpan(2);
            @include showPortrait;
        }

        &:nth-child(10) {
            @include columnStart(7);
            @include columnSpan(6);
            @include rowStart(5);
            @include rowSpan(1);
        }

        &:nth-child(11) {
            @include columnStart(7);
            @include columnSpan(6);
            @include rowStart(6);
            @include rowSpan(1);
        }

        &:nth-child(12) {
            @include columnStart(1);
            @include columnSpan(6);
            @include rowStart(7);
            @include rowSpan(1);
        }

        &:nth-child(13) {
            @include columnStart(7);
            @include columnSpan(3);
            @include rowStart(7);
            @include rowSpan(1);
            @include showPortrait;
        }

        &:nth-child(14) {
            @include columnStart(10);
            @include columnSpan(3);
            @include rowStart(7);
            @include rowSpan(1);
            @include showPortrait;
        }

    }
}
