.video-embed {
    &__btn {
        width: 80px;
        height: 80px;

        @include from(mp) {
            width: 160px;
            height: 160px;
        }

        &, & > * {
            transition: transform 0.3s;
        }

        @media (hover: hover) {
            &:hover {
                transform: scale(0.9, 0.9);
                > * {
                    transform: scale(1.1, 1.1);
                }
            }
        }
    }
    iframe {
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
    }
}
